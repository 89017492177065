// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-es-404-js": () => import("./../src/pages/es/404.js" /* webpackChunkName: "component---src-pages-es-404-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-imprint-page-js": () => import("./../src/templates/imprint-page.js" /* webpackChunkName: "component---src-templates-imprint-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-jobs-page-js": () => import("./../src/templates/jobs-page.js" /* webpackChunkName: "component---src-templates-jobs-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-productfamily-page-js": () => import("./../src/templates/productfamily-page.js" /* webpackChunkName: "component---src-templates-productfamily-page-js" */),
  "component---src-templates-solution-page-js": () => import("./../src/templates/solution-page.js" /* webpackChunkName: "component---src-templates-solution-page-js" */),
  "component---src-templates-terms-of-service-page-js": () => import("./../src/templates/terms-of-service-page.js" /* webpackChunkName: "component---src-templates-terms-of-service-page-js" */)
}

